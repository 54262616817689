export const Umsokn = () => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.84375 8.60615H5.12646"
      stroke="#626366"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8735 5.46553H5.12646"
      stroke="#626366"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2609 4.875C14.2609 5.28921 14.5967 5.625 15.0109 5.625C15.4251 5.625 15.7609 5.28921 15.7609 4.875H14.2609ZM4.75 15.9173C5.16421 15.9173 5.5 15.5815 5.5 15.1673C5.5 14.7531 5.16421 14.4173 4.75 14.4173V15.9173ZM4.52468 2.01257H11.4639V0.512573H4.52468V2.01257ZM1.75 11.4474V4.78726H0.25V11.4474H1.75ZM14.2609 4.80954V4.875H15.7609V4.80954H14.2609ZM4.75 14.4173H4.71993V15.9173H4.75V14.4173ZM1.75 4.78726C1.75 4.15597 1.96527 3.54355 2.36028 3.05111L1.1902 2.11254C0.581649 2.8712 0.25 3.81469 0.25 4.78726H1.75ZM2.32388 13.2022C1.95101 12.6931 1.75 12.0785 1.75 11.4474H0.25C0.25 12.3972 0.552533 13.3223 1.11373 14.0885L2.32388 13.2022ZM1.11373 14.0885C1.95562 15.2381 3.29509 15.9173 4.71993 15.9173V14.4173C3.77323 14.4173 2.88325 13.966 2.32388 13.2022L1.11373 14.0885ZM13.633 3.04369C14.0391 3.54258 14.2609 4.16623 14.2609 4.80954H15.7609C15.7609 3.82122 15.4202 2.86312 14.7962 2.09667L13.633 3.04369ZM11.4639 2.01257C12.3052 2.01257 13.1018 2.39126 13.633 3.04369L14.7962 2.09667C13.9802 1.09435 12.7564 0.512573 11.4639 0.512573V2.01257ZM4.52468 0.512573C3.22806 0.512573 2.00151 1.1011 1.1902 2.11254L2.36028 3.05111C2.8869 2.39458 3.68305 2.01257 4.52468 2.01257V0.512573Z"
      fill="#626366"
    />
    <rect
      x="7.10986"
      y="7.45081"
      width="13.8901"
      height="13.8119"
      rx="3.58028"
      fill="white"
    />
    <path
      d="M16.9012 15.927H11.1814"
      stroke="#626366"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9284 12.7865H11.1814"
      stroke="#626366"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2434 7.45081C17.2434 7.45081 10.8447 7.45381 10.8347 7.45381C8.5343 7.46656 7.10986 8.82899 7.10986 10.9071V17.8063C7.10986 19.895 8.54513 21.2627 10.8656 21.2627C10.8656 21.2627 17.2634 21.2604 17.2743 21.2604C19.5747 21.2477 21 19.8845 21 17.8063V10.9071C21 8.81849 19.5639 7.45081 17.2434 7.45081Z"
      stroke="#626366"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
