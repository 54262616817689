/*
 * Dev tools controller.
 * show / hide dev tool controls (for grid overlay button)
 * ctrl & K toggles controls
 */
import { useKeyDown } from '@hxm/hooks/useKeyDown';
import { useLocalStorage } from '@hxm/hooks/useLocalStorage';

import { GridOverlay } from './GridOverlay';

const LOCAL_STORAGE_KEY_GRID_VISIBLE = '_devGridVisible';

export const Devtools = () => {
  const [gridVisible, setGridVisible] = useLocalStorage(
    LOCAL_STORAGE_KEY_GRID_VISIBLE,
    false
  );
  const handleKeys = () => {
    setGridVisible(!gridVisible);
  };

  useKeyDown('g', handleKeys, true);

  return (
    <GridOverlay
      visible={gridVisible ? gridVisible : false}
      handleToggle={() => setGridVisible(!gridVisible)}
    />
  );
};
