import { useState } from 'react';
import { ChevronDown as Chevron } from '@hxm/assets/svg/ui/ChevronDown';
import { useLocaleContext } from '@hxm/contexts/locale';
import { logger } from '@hxm/utils/logger';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';

import s from './LocaleSelect.module.scss';

const c = classNames.bind(s);

type Props = {
  locales: Array<string>;
  selectedLocale?: string;
};

export const LocaleSelect = ({ locales, selectedLocale }: Props) => {
  const [activeLocale, setActiveLocale] = useState(selectedLocale ?? locales[0]);
  const [showLocales, setShowLocales] = useState(false);
  const { updateActiveLocale } = useLocaleContext();
  const router = useRouter();

  return (
    <div className={s.localeSelect}>
      <button
        type="button"
        className={s.localeSelect__current}
        onClick={() => setShowLocales(!showLocales)}
      >
        {activeLocale}
        <Chevron className={c(s.localeSelect__chevron, { active: showLocales })} />
      </button>
      {showLocales && (
        <ul className={s.localeSelect__locales}>
          {locales
            .filter((u) => u !== activeLocale)
            .map((locale, i) => (
              <li key={i}>
                <button
                  type="button"
                  className={s.localeSelect__item}
                  onClick={() => {
                    setActiveLocale(locale);
                    updateActiveLocale(locale);
                    router.push(router.asPath, router.asPath, { locale }).catch((e) => {
                      logger.error('unable to update route', { exception: e as Error });
                    });
                    setShowLocales(!showLocales);
                  }}
                >
                  {locale}
                </button>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
