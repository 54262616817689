import { Loading } from '@hxm/components/loading/Loading';
import { PageTransition } from '@hxm/components/page-transition/PageTransition';
import { useRouter } from 'next/router';

import s from './LayoutWelcome.module.scss';

type IProps = {
  children: React.ReactNode;
};

export const LayoutWelcome = ({ children }: IProps) => {
  const router = useRouter();

  return (
    <>
      <Loading />
      <PageTransition route={router.asPath}>
        <main id="main" className={s.layoutWelcome}>
          {children}
        </main>
      </PageTransition>
    </>
  );
};
