import { useMemo } from 'react';
import type { NavItem } from '@hxm/types/navigation';

export const useLocalNavigationItems = () => {
  const navigationItems = useMemo<Array<NavItem>>(() => {
    return [
      {
        title: 'Lánareiknivél',
        link: '/lanareiknivel',
        icon: 'Lan',
        enabled: true,
      },
      {
        title: 'Innskráning',
        link: '/#',
        icon: 'Yfirlit',
        enabled: true,
      },
    ].filter(({ enabled }) => enabled);
  }, []);

  return navigationItems;
};
