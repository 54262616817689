export const Sereign = () => (
  <svg viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.4668 13.9016L8.45993 10.0115L11.8741 12.6934L14.8032 8.91309"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="18.2176"
      cy="3.32039"
      r="1.9222"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1465 2.2403H5.87872C2.86728 2.2403 1 4.37302 1 7.38446V15.4668C1 18.4783 2.83066 20.6019 5.87872 20.6019H14.4828C17.4943 20.6019 19.3616 18.4783 19.3616 15.4668V8.42794"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
