export const Skjol = () => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9715 15.0667C20.9715 18.8 18.7746 21 15.0466 21H6.93438C3.19686 21 1 18.8 1 15.0667V6.93333C1 3.2 2.36947 1 6.09748 1H8.18022C8.92857 1 9.63325 1.35284 10.0823 1.95238L11.0333 3.21905C11.4833 3.81741 12.1873 4.16994 12.9353 4.17143H15.8835C19.621 4.17143 21 6.07619 21 9.88571L20.9715 15.0667Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.9834 13.1585H15.9786"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
