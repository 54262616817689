import React, { useContext, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { UIContext } from '@hxm/contexts/ui';

import { LoadingIndicator } from './LoadingIndicator';

import s from './Loading.module.scss';

// wait X ms before showing loading screen
const LOADING_DELAY = 1500;
// const LOADING_TIMEOUT = 20000; // remove timeout

export const Loading = () => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { uiState } = useContext(UIContext);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={uiState.isLoading}
      timeout={uiState.prefersReducedMotion ? 0 : LOADING_DELAY}
      classNames={{ ...s }}
      unmountOnExit
    >
      <div
        ref={nodeRef}
        className={s.loading}
        role="alertdialog"
        aria-busy="true"
        aria-live="assertive"
      >
        <LoadingIndicator />
      </div>
    </CSSTransition>
  );
};
