import { Devtools } from '@hxm/components/devtools/Devtools';
import { Meta } from '@hxm/components/meta/Meta';
import { LayoutWelcome } from '@hxm/containers/layout/LayoutWelcome';
import { Settings } from '@hxm/contentful/types/contentful-types';
import { LocaleProvider } from '@hxm/contexts/locale';
import { SettingsProvider } from '@hxm/contexts/settings';
import { UIProvider } from '@hxm/contexts/ui';
import { useOnMount } from '@hxm/hooks/useOnMount';
import { useThemeColorInitializer } from '@hxm/hooks/useThemeColorInitializer';
import { setCSSVariable } from '@hxm/styles/utils/setCSSVariable';
import { getBrowserSCrollbarWidth } from '@hxm/utils/getBrowserSCrollbarWidth';
import { SessionProvider } from 'context/session';
import { AppProps as NextAppProps } from 'next/app';
import PlausibleProvider from 'next-plausible';

import { Layout } from 'containers/layout/Layout';

import { User } from 'types/iron';

import '@hxm/styles/global.scss';
import 'react-loading-skeleton/dist/skeleton.css';

type InheritedPageProps = {
  settings?: Settings;
  user?: User;
  messages?: Record<string, string>;
};

type AppProps<P> = {
  pageProps: P;
} & Omit<NextAppProps<P>, 'pageProps'>;

export default function App({
  Component,
  pageProps,
  router,
}: AppProps<InheritedPageProps>) {
  const settings = pageProps.settings;

  const isDev = process.env.NODE_ENV === 'development';
  const isLoggedinUrl = /^\/minar-sidur/.test(router.pathname);
  const locale = router.locale ?? 'is';

  useOnMount(() =>
    setCSSVariable('--browser-scrollbar-width', `${getBrowserSCrollbarWidth()}px`)
  );

  useThemeColorInitializer(settings);

  const plausibleDomain = settings?.plausibleDomain ?? '';

  return (
    <PlausibleProvider domain={plausibleDomain} enabled={!!plausibleDomain}>
      <Meta favIconUrl={settings?.favIcon?.url ?? undefined} />
      <SettingsProvider settings={settings}>
        <SessionProvider user={pageProps.user}>
          <LocaleProvider
            locale={locale}
            messages={pageProps.messages}
            updateActiveLocale={() => null}
          >
            <UIProvider>
              {isLoggedinUrl ? (
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              ) : (
                <LayoutWelcome>
                  <Component {...pageProps} />
                </LayoutWelcome>
              )}
            </UIProvider>
          </LocaleProvider>
        </SessionProvider>
      </SettingsProvider>
      {isDev && <Devtools />}
    </PlausibleProvider>
  );
}
