import { useEffect } from 'react';
import { Settings } from '@hxm/contentful/types/contentful-types';
import { hexToRgb } from '@hxm/styles/utils/hexToRgb';
import { setCSSVariable } from '@hxm/styles/utils/setCSSVariable';

export const useThemeColorInitializer = (settings: Settings | undefined) => {
  useEffect(() => {
    if (settings) {
      const {
        colorPrimaryUi,
        colorPrimary600,
        colorPrimary400,
        colorPrimary200,
        colorPrimary100,
        colorPrimaryText,
      } = settings;

      if (
        colorPrimaryUi &&
        colorPrimary600 &&
        colorPrimary400 &&
        colorPrimary200 &&
        colorPrimary100 &&
        colorPrimaryText
      ) {
        const colorPrimary600Rgb = hexToRgb(colorPrimary600);
        const colorPrimary100Rgb = hexToRgb(colorPrimary100);
        setCSSVariable('--color-primary-ui', colorPrimaryUi);
        setCSSVariable('--color-primary', colorPrimary600);
        setCSSVariable('--color-primary-600', colorPrimary600);
        setCSSVariable('--color-primary-400', colorPrimary400);
        setCSSVariable('--color-primary-200', colorPrimary200);
        setCSSVariable('--color-primary-100', colorPrimary100);
        setCSSVariable('--color-primary-text', colorPrimaryText);
        colorPrimary600Rgb &&
          setCSSVariable('--color-loading-border', colorPrimary600Rgb);
        colorPrimary100Rgb &&
          setCSSVariable('--color-primary-100-rgb', colorPrimary100Rgb);
      }
    }
  }, [settings]);
};
