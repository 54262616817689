export const Settings = () => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.709 6.56191L20.0091 5.4381C19.417 4.48718 18.1049 4.15914 17.0758 4.70476V4.70476C16.586 4.97178 16.0015 5.04753 15.4512 4.91532C14.901 4.78311 14.4301 4.4538 14.1425 4C13.9575 3.71156 13.8581 3.38302 13.8544 3.04762V3.04762C13.8711 2.50988 13.6518 1.98879 13.2466 1.60305C12.8414 1.21731 12.2847 0.999778 11.7033 1H10.2933C9.72371 0.999994 9.17763 1.21001 8.77586 1.58357C8.37409 1.95713 8.14976 2.46345 8.1525 2.99048V2.99048C8.13561 4.07861 7.17748 4.95249 6.00143 4.95238C5.63897 4.9489 5.28392 4.85691 4.97221 4.68571V4.68571C3.94317 4.14009 2.63103 4.46813 2.03894 5.41905L1.28761 6.56191C0.696233 7.51163 1.04592 8.72505 2.06981 9.27619V9.27619C2.73536 9.63175 3.14536 10.2889 3.14536 11C3.14536 11.7111 2.73536 12.3682 2.06981 12.7238V12.7238C1.04722 13.2712 0.697151 14.4817 1.28761 15.4286V15.4286L1.99777 16.5619C2.27519 17.0251 2.74065 17.3669 3.29115 17.5117C3.84166 17.6565 4.43182 17.5923 4.93104 17.3333V17.3333C5.4218 17.0683 6.00663 16.9957 6.55551 17.1317C7.1044 17.2676 7.57187 17.6008 7.85402 18.0571C8.03903 18.3456 8.13844 18.6741 8.1422 19.0095V19.0095C8.1422 20.1088 9.10527 21 10.2933 21H11.7033C12.8873 21 13.8487 20.1146 13.8544 19.019V19.019C13.8516 18.4904 14.0774 17.9826 14.4814 17.6088C14.8854 17.2349 15.4341 17.026 16.0054 17.0286C16.367 17.0375 16.7206 17.1291 17.0347 17.2952V17.2952C18.061 17.8425 19.3723 17.5189 19.9679 16.5714V16.5714L20.709 15.4286C20.9958 14.973 21.0745 14.4304 20.9277 13.9209C20.7809 13.4115 20.4207 12.9772 19.9268 12.7143V12.7143C19.4329 12.4514 19.0726 12.0171 18.9258 11.5076C18.779 10.9982 18.8577 10.4556 19.1446 10C19.3311 9.69865 19.6011 9.44879 19.9268 9.27619V9.27619C20.9445 8.72535 21.2934 7.51902 20.709 6.57143V6.57143V6.56191Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="11.0032"
      cy="11"
      rx="2.96415"
      ry="2.74286"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
