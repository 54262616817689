export const Logout = () => (
  <svg viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4149 5.38892C16.67 2.96622 13.8247 1.38892 10.611 1.38892C5.30298 1.38892 1 5.6919 1 10.9999C1 16.3079 5.30298 20.6109 10.611 20.6109C13.4663 20.6109 16.0307 19.3658 17.7911 17.3889"
      stroke="#4F4F4F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 11.3889L24.25 11.3889"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.7185 7.80326C21.3951 7.54451 20.9231 7.59695 20.6643 7.92039C20.4056 8.24384 20.458 8.71581 20.7815 8.97457L21.7185 7.80326ZM25 11.3889L25.4685 11.9746C25.6464 11.8322 25.75 11.6168 25.75 11.3889C25.75 11.1611 25.6464 10.9456 25.4685 10.8033L25 11.3889ZM20.7815 13.8033C20.458 14.062 20.4056 14.534 20.6643 14.8574C20.9231 15.1809 21.3951 15.2333 21.7185 14.9746L20.7815 13.8033ZM20.7815 8.97457L24.5315 11.9746L25.4685 10.8033L21.7185 7.80326L20.7815 8.97457ZM24.5315 10.8033L20.7815 13.8033L21.7185 14.9746L25.4685 11.9746L24.5315 10.8033Z"
      fill="currentColor"
    />
  </svg>
);
