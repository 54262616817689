import { useSettings } from '@hxm/hooks/useSettings';

import s from './Logo.module.scss';

export const Logo = () => {
  const { settings } = useSettings();

  if (!settings?.logo?.url) {
    return null;
  }

  // eslint-disable-next-line @next/next/no-img-element
  return <img src={settings.logo.url} alt="Logo" className={s.logo} />;
};
