// container Nav component, any data for nav structure should go here
// container gets imported into 'components/layout/Layout'

import { NavLink } from '@hxm/components/nav/NavLink';
import { NavSecondary } from '@hxm/components/nav/NavSecondary';
import { useRouter } from 'next/router';

const determineCurrent = (pathname: string, link: string) => {
  if (pathname.includes('/[slug]')) {
    return pathname.replace('/[slug]', '') === link;
  } else {
    return pathname === link;
  }
};

export const NavSecondaryContainer = ({
  childPages,
}: {
  childPages: Array<{ title: string; link: string }>;
}) => {
  const router = useRouter();

  return (
    <NavSecondary>
      {childPages.map((child, i) => (
        <NavLink
          key={i}
          to={child.link}
          current={determineCurrent(router.pathname, child.link)}
        >
          {child.title}
        </NavLink>
      ))}
    </NavSecondary>
  );
};
