import { useContext, useEffect } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { UIContext } from '@hxm/contexts/ui';
import { Router } from 'next/router';

import { Loading } from '../loading/Loading';

type TPageTransitionProps = {
  route: string;
  children: React.ReactNode;
};

type NextJsRouteError = Error & { cancelled?: boolean };

export const PageTransition = ({ route, children }: TPageTransitionProps) => {
  const { uiState, setUIState } = useContext(UIContext);

  const transitionDisabled = !uiState.canTransition || uiState.prefersReducedMotion;

  const handleStart = () => {
    setUIState({ isNavOpen: false });
  };

  const handleEntering = () => {
    if (!transitionDisabled) {
      window.scrollTo(0, 0);
    }
  };

  const handleEntered = () => {
    setUIState({
      canTransition: false,
      canScroll: true,
    });
  };

  useEffect(() => {
    function handleRouteStart() {
      setUIState({ isLoading: true });
    }

    function handleRouteComplete(err?: NextJsRouteError) {
      // hide loading screen
      setUIState({ isLoading: false });

      // do something for if action is cancelled?
      if (err && err.cancelled) {
        // console.info('cancelled');
      }
    }

    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteComplete);
    Router.events.on('routeChangeError', (err: NextJsRouteError) =>
      handleRouteComplete(err)
    );

    return () => {
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteComplete);
      Router.events.off('routeChangeError', handleRouteComplete);
    };
  }, [setUIState]);

  return (
    <>
      <TransitionGroup component={null}>
        <Transition
          key={route}
          onExit={handleStart}
          onEntering={handleEntering}
          onEntered={handleEntered}
          timeout={0}
        >
          {children}
        </Transition>
      </TransitionGroup>

      <Loading />
    </>
  );
};
