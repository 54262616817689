export const Lan = () => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.68101 21H4.6557C2.63671 21 1 19.4607 1 17.5618V8.84736C1.00778 8.09967 1.38132 7.39702 2.01266 6.94256L8.90886 1.6853C10.1223 0.771566 11.8473 0.771566 13.0608 1.6853L19.9873 6.93303C20.6163 7.38935 20.9892 8.09083 21 8.83784V13.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 11V21"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4446 15V21"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3334 18V21"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
