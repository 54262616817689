export const Lifeyrisgreidslur = () => (
  <svg viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9998 14.2813H16.7694C15.216 14.2802 13.957 12.9178 13.9561 11.2355C13.9561 9.55331 15.216 8.19082 16.7694 8.18979H20.9998"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2479 11.1659H16.9222"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.48376 1.38461H15.5161C18.5446 1.38461 20.9998 4.04358 20.9998 7.32345V15.4458C20.9998 18.7256 18.5446 21.3846 15.5161 21.3846H6.48376C3.45522 21.3846 1 18.7256 1 15.4458V7.32345C1 4.04358 3.45522 1.38461 6.48376 1.38461Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.73975 6.52043H11.3816"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
