import { ILinkProps, Link } from '../link/Link';

export const NavLink = ({
  to,
  children,
  className,
  current,
}: ILinkProps & { current?: boolean }) => {
  return (
    <Link
      to={to}
      aria-current={current ? 'page' : undefined}
      tabIndex={0}
      className={className}
    >
      {children}
    </Link>
  );
};
