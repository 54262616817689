import React, { useContext, useEffect } from 'react';
import { Header } from '@hxm/components/header/Header';
import { Loading } from '@hxm/components/loading/Loading';
import { PageTransition } from '@hxm/components/page-transition/PageTransition';
import { NavSecondaryContainer as SubNav } from '@hxm/containers/nav/NavSecondary';
import { UIContext } from '@hxm/contexts/ui';
import { useLocalNavigationItems } from '@hxm/hooks/useLocalCalculatorNavigation';
import { useNavigationItems } from '@hxm/hooks/useNavigationItems';
import { useResize } from '@hxm/hooks/useResize';
import { logger } from '@hxm/utils/logger';
import classnames from 'classnames/bind';
import { useRouter } from 'next/router';
import { SWRConfig } from 'swr';

import { NavContainer as Nav } from '../nav/Nav';
import { LoggedinUserContainer } from '../users/LoggedInUser';

import s from './Layout.module.scss';

const c = classnames.bind(s);

type IProps = {
  children: React.ReactNode;
  publicNav?: boolean;
};

const redirects: Record<string, string | undefined> = {
  '403': '/',
  '404': '/minar-sidur/yfirlit',
  '500': '/minar-sidur/yfirlit',
};

export const Layout = ({ children, publicNav = false }: IProps) => {
  const { uiState, setUIState } = useContext(UIContext);
  const { sizes } = useResize();

  const router = useRouter();

  const privateNavigation = useNavigationItems();
  const publicNavigation = useLocalNavigationItems();

  const navigation = publicNav ? publicNavigation : privateNavigation;

  useEffect(() => {
    setUIState({ isNavOpen: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const childPages = navigation.find((itm) =>
    router.pathname.includes(itm.link)
  )?.childPages;

  return (
    <SWRConfig
      value={{
        onError: (error: { status?: number | string }) => {
          const status = error.status ?? 0;
          const redirect = redirects[status.toString()] ?? '/';

          if (status === 403 || status === 404 || status === 500) {
            router.push(redirect).catch((e) => {
              logger.error('Error redirecting to login page', {
                exception: e as Error,
              });
            });
          }
        },
      }}
    >
      <Loading />
      <PageTransition route={router.asPath}>
        <div className={c(s.layout, { [s.navOpen]: uiState.isNavOpen })}>
          <div className={s.layout__wrap}>
            <Header>{!publicNav && <LoggedinUserContainer />}</Header>
            <Nav navigation={navigation} publicNav={publicNav} />
            {/* ID for skip link */}
            <div className={s.layout__content}>
              {!sizes.isMobile && childPages && childPages.length > 1 && (
                <SubNav childPages={childPages} />
              )}
              <main id="main">{children}</main>
            </div>
          </div>
        </div>
      </PageTransition>
    </SWRConfig>
  );
};
