type SvgProps = {
  className?: string;
};
export const ChevronDown = ({ className }: SvgProps) => (
  <svg
    className={className}
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 1 5.5 6 1 1" stroke="currentColor" />
  </svg>
);
