import * as React from 'react';
import { fixLink } from '@hxm/utils/fixLink';
import classnames from 'classnames/bind';

import { ButtonSpinner } from '../button/ButtonSpinner';
import { Link } from '../link/Link';

import s from './Button.module.scss';

const c = classnames.bind(s);

type IProps = {
  to?: string;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  [key: string]: unknown;
  transition?: boolean;
  size?: 'small' | 'large';
  variant?: 'secondary';
  loading?: boolean;
};

export const Button = ({
  to,
  children,
  className,
  disabled,
  transition,
  variant,
  size,
  loading,
  ...props
}: IProps) => {
  const passProps: React.ButtonHTMLAttributes<HTMLButtonElement> &
    React.AnchorHTMLAttributes<HTMLAnchorElement> = { ...props };

  const fixedTo = to && fixLink(to);
  const isExternal = fixedTo && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(fixedTo || '');

  passProps.className = c(className, variant, size, {
    button: true,
    disabled,
  });

  if (isExternal) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={fixedTo} {...passProps}>
        {children}
      </a>
    );
  }

  if (fixedTo) {
    return (
      <Link to={fixedTo} transition={transition} {...passProps}>
        {children}
      </Link>
    );
  }

  return (
    <button
      type={passProps.type === 'submit' ? 'submit' : 'button'}
      {...passProps}
      disabled={disabled}
      aria-disabled={disabled}
    >
      {loading ? <ButtonSpinner /> : children}
    </button>
  );
};
