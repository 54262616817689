import { useContext, useRef } from 'react';
import { Menu } from '@hxm/assets/svg/menu/Menu';
import { UIContext } from '@hxm/contexts/ui';
import { useScrollDirection } from '@hxm/hooks/useScrollDirection';
import classnames from 'classnames/bind';

import { Link } from '../link/Link';
import { Logo } from '../logo/Logo';

import s from './Header.module.scss';

const c = classnames.bind(s);

export const Header = ({ children }: { children?: React.ReactNode }) => {
  const { setUIState } = useContext(UIContext);
  const scrollDirection = useScrollDirection();

  const headerRef = useRef<HTMLElement>(null);

  return (
    <header
      className={c(s.header, { [s.hidden]: scrollDirection === 'down' })}
      ref={headerRef}
    >
      <div className={s.header__container}>
        <a tabIndex={0} className={s.header__skip} href="#main">
          Skip to content
        </a>

        <Link
          tabIndex={0}
          to="/minar-sidur/yfirlit"
          title="Forsíða"
          className={s.header__logo}
        >
          <Logo />
        </Link>

        <div className={s.header__content}>{children}</div>

        <div className={s.header__controls}>
          <button
            type="button"
            onClick={() => setUIState({ isNavOpen: true })}
            aria-label="Valmynd"
          >
            <Menu />
          </button>
        </div>
      </div>
    </header>
  );
};
