import { createContext } from 'react';
import { Settings } from '@hxm/contentful/types/contentful-types';
import { swrFetcher } from '@hxm/utils/clientFetch';
import useSWR from 'swr';

type SettingsContext = {
  settings: Settings | undefined;
  children?: React.ReactNode;
};

export const SettingsContext = createContext<SettingsContext>({
  settings: undefined,
});

export const SettingsProvider = ({ settings, children }: SettingsContext) => {
  const { data } = useSWR<Settings>('/api/contentful/settings', swrFetcher, {
    fallbackData: settings,
  });

  return (
    <SettingsContext.Provider value={{ settings: data }}>
      {children}
    </SettingsContext.Provider>
  );
};
