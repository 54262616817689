import React from 'react';
import { navigationMessages } from '@hxm/contentful/messages/messages.navigation';
import { useMessages } from '@hxm/hooks/useMessages';

import s from './LoggedinUser.module.scss';

type Props = {
  children: React.ReactNode;
  locales?: React.ReactElement;
};

export const LoggedinUser = ({ children, locales }: Props) => {
  const { formatMessage } = useMessages();

  return (
    <div className={s.loggedinUser}>
      <span className={s.loggedinUser__user}>
        {`${formatMessage(navigationMessages.userGreeting)}, `}
        {children}
      </span>
      {locales}
      {/* <Link to={'/notifications'} className={s.loggedinUser__notifications}>
        <Notification />
        {notifications && (
          <span className={s.loggedinUser__notificationsCount}>{notifications}</span>
        )}
      </Link> */}
    </div>
  );
};
