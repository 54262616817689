export const Idgjold = () => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.098 15.5477H6.8623"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.098 11H6.8623"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1373 6.45224H6.8623"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5911 1C15.5911 1 6.37774 1.00435 6.36334 1.00435C3.051 1.02281 1 2.99565 1 6.00489V15.9951C1 19.0196 3.06661 21 6.40774 21C6.40774 21 15.6199 20.9967 15.6355 20.9967C18.9478 20.9783 21 19.0043 21 15.9951V6.00489C21 2.98045 18.9322 1 15.5911 1Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
